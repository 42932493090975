export enum HorseColor {
  BROWN = 1,
  DARK_BROWN = 2,
  BLACK = 3,
  FOX = 5,
  GREY = 6,
  PINTO = 7,
  BLACK_BROWN = 8,
  PALOMINO = 9,
  DUN = 10,
  DARK_FOX = 11
}

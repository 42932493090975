import {environment} from '../../environments/environment';

export const layoutItems = {
  start:
    {name: $localize`:@@horsetelexAdmin:Horsetelex Admin`, image: 'assets/icons/layout/horse_logo.png', url: ''},
  sidebarItems: [
    {name: $localize`:@@startItem:Start`, image: 'assets/icons/layout/icons8-zuhause-64.png', url: ''},
    {
      name: $localize`:@@pedigree:Pedigree`, image: 'assets/icons/layout/pedigree_icon.jpg', url: '/manage',
      subItems: [
        {name: $localize`:@@layoutItemImportData:Pferde importieren`, url: '/data-import/horses'},
        {name: $localize`:@@clientHorseOrderingItem:Samenhändler Sortierung`, url: '/manage/clienthorse-ordering'},
        {name: $localize`:@@layoutItemMergeHorses:Pferde zusammenführen`, url: '/manage/merge-horses'},
      ]
    },
    {
      name: $localize`:@@results:Results`, image: 'assets/icons/layout/results_icon.jpg', url: '/results',
      subItems: [],
      disabled: !environment.featureFlags.isResultsNewModeEnabled,
    },
    {
      name: $localize`:@@cmsItem:CMS`, image: 'assets/icons/layout/icons8-seitenübersicht-4-64.png', url: '/cms',
      subItems: [
        {name: $localize`:@@simplePagesItem:Einfache Seiten`, url: '/content-management/simple-pages'},
        {name: $localize`:@@questionsAndAnswersItem:Fragen und Antworten`, url: '/content-management/questions-and-answers'},
        {name: $localize`:@@memberFeaturesItem:Mitgliederfunktionen`, url: '/content-management/member-functions'},
      ]
    },
    {
      name: $localize`:@@adsItem:Werbeanzeigen`, image: 'assets/icons/layout/icons8-werbung-64.png', url: '/ads',
      subItems: [
        {name: $localize`:@@headlineBlocksItem:Schlagzeilen-Blöcke`, url: '/ads/headline-blocks'},
        {name: $localize`:@@adsSubItem:Anzeigen`, url: '/ads/commercials'},
        // {name: $localize`:@@adsSpacesItem:Anzeigeplätze`, url: '/ads/ad-spaces'},
        {name: $localize`:@@adsSpaceGroup:Anzeigeplatzgruppe`, url: '/ads/ad-space-groups'},
        {name: $localize`:@@adsPerformance:Performance`, url: '/ads/performance'},
        {name: $localize`:@@adsPopupBanners:Popup-Banner`, url: '/ads/popup-banners'}
      ]
    },
    {
      name: $localize`:@@crawling:Crawling`,
      url: '/crawling',
      image: 'assets/icons/layout/tasks.png',
      disabled: !environment.featureFlags.isCrawlingEnabled,
      subItems: [
        {name: $localize`:@@crawlingTasks:Crawling-Aufträge`, url: '/crawling/crawling-tasks'},
        {name: $localize`:@@crawlerOverview:Crawler-Übersicht`, url: '/crawling/crawler-overview'},
        {name: $localize`:@@eventCodeMappings:Event Code Mappings`, url: '/results/eventCodeMappings'},
        {name: $localize`:@@tourneyCrawlingSingle:Turnier-Crawling (Einzeln)`, url: '/crawling/tourney-crawling'},
        {name: $localize`:@@feiTourneyCrawlingSearch:FEI Turnier-Crawling (Suche)`, url: '/crawling/tourney-search-page-crawling'},
        {name: $localize`:@@tourneyCrawlingSearchEquiScore:Equi-Score Turnier-Crawling (Suche)`, url: '/crawling/equi-score-search-page-crawling'},
        {name: $localize`:@@tourneyCrawlingSearchOnlineEquipe:Online Equipe Turnier-Crawling (Suche)`, url: '/crawling/online-equipe-search-page-crawling'},
      ]
    },
    {
      name: $localize`:@@accounting:Buchhaltung`, image: 'assets/icons/layout/calculator.png', url: '',
      subItems: [
        {name: $localize`:@@subscriptions:Abonnements`, url: '/accounting/subscriptions'},
        {name: $localize`:@@orders:Bestellungen`, url: '/accounting/orders'},
        {name: $localize`:@@pendingPayments:Ausstehende Zahlungen`, url: '/accounting/orders/pending-payments'}
      ]
    }
  ],
  user: {
    user: 'assets/icons/layout/icons8-benutzer-30.png',
    logout: 'assets/icons/layout/icons8-logout-abgerundet-24.png',
    profile: 'assets/icons/layout/icons8-geschlechtsneutraler-benutzer-32.png',
    settings: 'assets/icons/layout/icons8-einstellungen-50.png'
  },
};

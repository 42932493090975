import { Pipe, PipeTransform } from '@angular/core';
import {HorseColor} from '../enums/horse-color';

@Pipe({
  name: 'horseColorTranslation'
})
export class HorseColorTranslationPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case HorseColor.BROWN:
        return $localize`:@@horseColorBlack:Brown`;
      case HorseColor.DARK_BROWN:
        return $localize`:@@horseColorDarkBrown:Dunkelbraun`;
      case HorseColor.BLACK:
        return $localize`:@@horseColorBlack:Rappe`;
      case HorseColor.FOX:
        return $localize`:@@horseColorFox:Fuchs`;
      case HorseColor.GREY:
        return $localize`:@@horseColorGrey:Grey`;
      case HorseColor.PINTO:
        return $localize`:@@horseColorPinto:Schecke`;
      case HorseColor.BLACK_BROWN:
        return $localize`:@@horseColorBlackBrown:Schwarzbraun`;
      case HorseColor.PALOMINO:
        return $localize`:@@horseColorPalomino:Palomino`;
      case HorseColor.DUN:
        return $localize`:@@horseColorDun:Falbe`;
      case HorseColor.DARK_FOX:
        return $localize`:@@horseColorDarkFox:Dunkelfuchs`;
      default:
        return '-';
    }
  }

}

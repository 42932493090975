import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

  transform(value: any): unknown {
    if (value && value !== 'false' && value !== '0') {
      return $localize`:@@booleanYes:Ja`;
    } else {
      return $localize`:@@booleanNo:Nein`;
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import {Breedingways} from '../enums/breedingway.enum';

@Pipe({
  name: 'breedingwayTranslation'
})
export class BreedingwayTranslationPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case Breedingways.NATRUAL_BREEDING:
        return $localize`:@@breedingwayNaturalBreeding:Normale insemination`;
      case Breedingways.EMBRYO_TRANSFER:
        return $localize`:@@breedingwayEmbryoTransfer:Embryotransfer`;
      case Breedingways.CLONE:
        return $localize`:@@breedingwayClone:Klon`;
      case Breedingways.TWIN:
        return $localize`:@@breedingwayEmbryoTwin:Zwilling`;
      default:
        return value.toString(10);
    }
  }

}

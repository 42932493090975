import {Pipe, PipeTransform} from '@angular/core';
import {HORSESEXES} from '../../../constants/HorseSexes';

@Pipe({
  name: 'horseGender'
})
export class HorseGenderPipe implements PipeTransform {

  transform(sex: number): string {
    return HORSESEXES[sex];
  }

}
